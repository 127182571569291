import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const MissingPage = () => (
    <Layout>
        <SEO title="Page not found"/>
        <div className="cols-2-1">
            <div>
                <h3 class="h3-on=h1">Error 404</h3>
                <h1>Page not found</h1>
                <p>Oops! You might have clicked on a link with a typo. Navigate this site with the menu above.</p>
            </div>
        </div>
    </Layout>
)

export default MissingPage